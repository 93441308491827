import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import DHFLink from '../components/DHFLink'

export default function designInputsAndVerificationTestsTemplate({
  data,
  pageContext,
}) {
  const { frontmatter } = data.markdownRemark
  const { filePath } = pageContext
  const filePathParts = filePath.split('/')
  const documentName = filePathParts[2]
  return (
    <Layout title={documentName} filePath={filePath}>
      <p>Product Description: {frontmatter.productDescription}</p>
      <table className="table is-bordered">
        <tr>
          <th rowSpan="2">ID</th>
          <th colSpan="2">Input Source</th>
          <th>Design Input</th>
          <th colSpan="2">Test Documentation</th>
          <th colSpan="2">Results Documentation</th>
        </tr>
        <tr>
          <th>Section</th>
          <th>Document Description</th>
          <th>&nbsp;</th>
          <th>Section</th>
          <th>Document Description</th>
          <th>Section</th>
          <th>Document Description</th>
        </tr>
        <tr>
          <td rowSpan="2">
            {linkOrData(Object.values(frontmatter.testTable[0])[0])}
          </td>
          <td>{linkOrData(Object.values(frontmatter.testTable[0])[1])}</td>
          <td>{linkOrData(Object.values(frontmatter.testTable[0])[2])}</td>
          <td rowSpan="2">
            {linkOrData(Object.values(frontmatter.testTable[0])[3])}
          </td>
          <td rowSpan="2">
            {linkOrData(Object.values(frontmatter.testTable[0])[4])}
          </td>
          <td rowSpan="2">
            {linkOrData(Object.values(frontmatter.testTable[0])[5])}
          </td>
          <td rowSpan="2">
            {linkOrData(Object.values(frontmatter.testTable[0])[6])}
          </td>
          <td rowSpan="2">
            {linkOrData(Object.values(frontmatter.testTable[0])[7])}
          </td>
        </tr>
        <tr>
          <td>{linkOrData(Object.values(frontmatter.testTable[1])[1])}</td>
          <td>{linkOrData(Object.values(frontmatter.testTable[1])[2])}</td>
        </tr>
        <tr>
          {Object.values(Object.values(frontmatter.testTable[2])).map(
            (value) => (
              <td>{linkOrData(value)}</td>
            )
          )}
        </tr>
        <tr>
          <td rowSpan="2">
            {linkOrData(Object.values(frontmatter.testTable[3])[0])}
          </td>
          <td>{linkOrData(Object.values(frontmatter.testTable[3])[1])}</td>
          <td>{linkOrData(Object.values(frontmatter.testTable[3])[2])}</td>
          <td rowSpan="2">
            {linkOrData(Object.values(frontmatter.testTable[3])[3])}
          </td>
          <td rowSpan="2">
            {linkOrData(Object.values(frontmatter.testTable[3])[4])}
          </td>
          <td rowSpan="2">
            {linkOrData(Object.values(frontmatter.testTable[3])[5])}
          </td>
          <td rowSpan="2">
            {linkOrData(Object.values(frontmatter.testTable[3])[6])}
          </td>
          <td rowSpan="2">
            {linkOrData(Object.values(frontmatter.testTable[3])[7])}
          </td>
        </tr>
        <tr>
          <td>{linkOrData(Object.values(frontmatter.testTable[4])[1])}</td>
          <td>{linkOrData(Object.values(frontmatter.testTable[4])[2])}</td>
        </tr>
        <tr>
          <td rowSpan="2">
            {linkOrData(Object.values(frontmatter.testTable[5])[0])}
          </td>
          <td>{linkOrData(Object.values(frontmatter.testTable[5])[1])}</td>
          <td>{linkOrData(Object.values(frontmatter.testTable[5])[2])}</td>
          <td rowSpan="2">
            {linkOrData(Object.values(frontmatter.testTable[5])[3])}
          </td>
          <td rowSpan="2">
            {linkOrData(Object.values(frontmatter.testTable[5])[4])}
          </td>
          <td rowSpan="2">
            {linkOrData(Object.values(frontmatter.testTable[5])[5])}
          </td>
          <td rowSpan="2">
            {linkOrData(Object.values(frontmatter.testTable[5])[6])}
          </td>
          <td rowSpan="2">
            {linkOrData(Object.values(frontmatter.testTable[5])[7])}
          </td>
        </tr>
        <tr>
          <td>{linkOrData(Object.values(frontmatter.testTable[6])[1])}</td>
          <td>{linkOrData(Object.values(frontmatter.testTable[6])[2])}</td>
        </tr>
        <tr>
          {Object.values(Object.values(frontmatter.testTable[7])).map(
            (value) => (
              <td>{linkOrData(value)}</td>
            )
          )}
        </tr>
        <tr>
          {Object.values(Object.values(frontmatter.testTable[8])).map(
            (value) => (
              <td>{linkOrData(value)}</td>
            )
          )}
        </tr>
        <tr>
          {Object.values(Object.values(frontmatter.testTable[9])).map(
            (value) => (
              <td>{linkOrData(value)}</td>
            )
          )}
        </tr>
        <tr>
          {Object.values(Object.values(frontmatter.testTable[10])).map(
            (value) => (
              <td>{linkOrData(value)}</td>
            )
          )}
        </tr>
        <tr>
          {Object.values(Object.values(frontmatter.testTable[11])).map(
            (value) => (
              <td>{linkOrData(value)}</td>
            )
          )}
        </tr>
        <tr>
          {Object.values(Object.values(frontmatter.testTable[12])).map(
            (value) => (
              <td>{linkOrData(value)}</td>
            )
          )}
        </tr>
        <tr>
          {Object.values(Object.values(frontmatter.testTable[13])).map(
            (value) => (
              <td>{linkOrData(value)}</td>
            )
          )}
        </tr>
        <tr>
          {Object.values(Object.values(frontmatter.testTable[14])).map(
            (value) => (
              <td>{linkOrData(value)}</td>
            )
          )}
        </tr>
      </table>
    </Layout>
  )
}

/**
 * Returns an a string if the input is data or a DHFLink component if it
 * is a DHF encoded link
 * @param value the input string
 * @returns {JSX.Element|*} DHFLink component if the input is DHF encoded link
 */
function linkOrData(value) {
  if (value.startsWith('LINK|')) {
    return <DHFLink link={value} />
  }
  return value
}

export const query = graphql`
  query MarkdownQueryDesignInputsAndVerificationTests($filePath: String) {
    markdownRemark(fields: { filePath: { eq: $filePath } }) {
      frontmatter {
        templateName
        productDescription
        testTable {
          id
          inputSourceSection
          inputSourceDocumentDescription
          designInput
          testDocumentationSection
          testDocumentationDocumentDescription
          resultsDocumentationSection
          resultsDocumentationDocumentDescription
        }
      }
    }
  }
`
